import React, { Component } from 'react';

/* THIS PAGE HAS BEEN MOVED TO DATA VISUALIZATION/DATA TABLE */
export default class LegendOverviewCode extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('/components/data-visualization/legend/code');
  }

  render() {
    return <div />;
  }
}
